
import { hydrateCSRTemplate } from "@fiverr-private/layout-lib/CSRTemplate";

import Sidebar from '/home/circleci/app/packages/layout-sidebar-component/src/entries/Guest';

const element = typeof document !== 'undefined' && document.querySelector('#Sidebar');
if (element) {
    const props = window.initialData['Sidebar'];

    hydrateCSRTemplate(Sidebar, props, element, 'Sidebar', 'Guest');
}
export default Sidebar;
